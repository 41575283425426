import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button as Button } from 'devextreme-react/button';
import DataGrid, {
  Column, Editing, SearchPanel, Scrolling,
  Export, ColumnChooser, Toolbar, Item, Summary,
  TotalItem, Form, Popup, Lookup,
  Paging
} from 'devextreme-react/data-grid';
import { Button as GrdButton } from 'devextreme-react/data-grid';
import { Sorting } from 'devextreme-react/data-grid';
import { ButtonGroup } from 'devextreme-react/button-group';
import { Col, DateRangePicker } from 'rsuite';
import * as utils from '../../../../utils/utils.js'
import * as consts from '../../../../utils/consts.js'

import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

// import { FaCaretLeft, FaFilter } from "react-icons/fa";
import { TbFilter } from "react-icons/tb";
// import { FcViewDetails } from "react-icons/fc";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from 'api.js';

import Moment from 'react-moment';
import moment from 'moment';
// import { TagBox } from 'devextreme-react';
import ColumnTagBox from './ColumnTagBox.jsx';
import ColumnSwitchBox from './ColumnSwitchBox.jsx';
import MyUserResetPasswordPopupComponent from 'components/popups/reset-password.jsx';

// import * as AspNetData from 'devextreme-aspnet-data-nojquery';

// import { useDropzone } from 'react-dropzone';
// import ReactDropzone from 'components/dropzone/dropzone.jsx';
// import { util } from 'prettier';
// import FileUploader from 'devextreme-react/file-uploader';

// this is autocompelte with remote query --
// https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/Autocomplete/Overview/MaterialBlueLight/
// ----

const ComplexTable = (props) => {
  // const [formData, setFormData] = useState();
  // var formData = new FormData();



  const useCustomSearchParams = () => {
    const [search, setSearch] = useSearchParams();
    const searchAsObject = Object.fromEntries(
      new URLSearchParams(search)
    );

    return [searchAsObject, setSearch];
  };
  const [searchParams] = useCustomSearchParams();


  const navigate = useNavigate();
  const { endpoint, gridName, item } = props
  const { ...urlParams } = useParams();

  // const [firstName, setFirstName] = useState("");
  // const [rawColumns, setRawColumns] = useState([]);

  // lookupData = (key = column, value = array of items)
  const [lookupData, setLookupData] = useState({});

  // const [gridColumns, setGridColumns] = useState([]);

  const selectedItemKeys = [];

  const [selectionRange, setSelectionRange] = useState([]);
  const [page, setPage] = useState(1);
  const [limit,setLimit] = useState(100);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const exportFormats = ['xlsx', 'csv'];
  const exportTexts = { exportAll: 'Save as {0}' };

  const BI_ADD = "__insert";
  const BI_EDIT = "__update";
  const BI_DELETE = "__remove";
  const BI_FILTER_DATE = window._env_.REACT_APP_BI_FILTER_DATE || process.env.REACT_APP_BI_FILTER_DATE;
  const BI_EXPORT_GRID = window._env_.REACT_APP_BI_EXPORT_GRID || process.env.REACT_APP_BI_EXPORT_GRID;
  const BI_DOWNLOAD_FULL = "__download_full";

  const [tableData, setTableData] = useState([])
  const [columnsData, setColumnsData] = useState([])
  const [allowedActions, setAllowedActions] = useState([])
  // const [isNewRow, setIsNewRow] = useState(false);
  const [formTitle, setFormTitle] = useState("__pagetitle__");
  // let isNewRow = false;

  // const dataSource = AspNetData.createStore({
  //   // key: 'Id',
  //   loadUrl: api.getRemoteEndpoint(endpoint) ,
  //   page:1,
  //   _limit:100,
  //   onBeforeSend: (operation, ajaxOptions) => {
  //     console.log("getRemoteEndpoint",api,operation);
  //     ajaxOptions.headers = {
  //       'Authorization':api.getAccessToken() ? 'Bearer ' + api.getAccessToken().access_token : ''
  //     }
  //   },
  // });

  const getActions = (item) => {
    if (!item.options) return;
    console.log("getActions", item.options);
    // let actions = [];
    item.options.map((option) => { //xx
      // actions.
      if (option.name.left(10) == 'actions.__') {
        console.log("ComplexTable getActions item option", option);
        // so, now we need to construct an oject with the following
        // options.name = actions.__<insert|update|delete>
        // options.value = <endpoint>|[method]
        const name = option.name.split(".")[1];
        const actionProps = option.value.split("|");

        console.log("actionProps", actionProps);
        const actionEndpoint = actionProps[0];
        const method = actionProps[1] || "GET";
        let icon = actionProps[2] || "";

        if (name == BI_EDIT && icon == "") {
          icon = "fa fa-pencil";
        }
        if (name == BI_DELETE && icon == "") {
          icon = "fa fa-trash";
        }

        const action = {
          "endpoint": actionEndpoint,
          "method": method,
          "name": name,
          "icon": icon,
        };
        allowedActions.push(action);
      }
    });
  }

  var x = {};
  const fetchData = (dataEndpoint) => {
    if (dataEndpoint !== "") {
      // here we need to get the url search params 

      api.get(dataEndpoint, searchParams, (response) => {
        console.log("fetchData", response);
        const data = response.data;
        const columnsData = response.fields;

        // let's loop on columns, and check which one has lookup
        columnsData.map((column) => {
          if (!utils.isEmpty(column.lookup)) {
            api.get(column.lookup, null, (response) => {
              const lookup_data = response.data;
              lookup_data.sort((a, b) => (a.item_value < b.item_value) ? -1 : 1);
              const name = column.dataField;
              x[name] = lookup_data;
              const y = utils.merge_options(lookupData, x)
              setLookupData(y);
            });
          }
        });

        columnsData.sort((a, b) => (a.display_order < b.display_order) ? -1 : 1);
        setColumnsData(columnsData);

        if (data) {
          setTableData(data);
          console.log("fetchData data", data);
        }
      });
    }
  }

  // this will get all data and save it in a csv file
  const fetchAll = () => {
    const url = utils.updateUrlParameter("_nolimit", 1);
    console.log("fetchall", endpoint, url);
    fetchData(endpoint);
  }

  useEffect(() => {
    fetchData(endpoint);
  }, [endpoint]);


  useEffect(() => {
    getActions(item);
  }, [item]);


  const allowPreviousPage = () => {
    console.log("allowPreviousPage", page, endpoint);
    if (page > 1) {return true;}
    return false;
  }
  const allowNextPage = () => {
    // if (page > 1) {return true;}
    return true;
  }
  const fetchPreviousPage = () => {
    setPage(page => page - 1);
    const url = utils.updateUrlParameter(endpoint, "_page", page); //this the full local url. we need to extract search params and set it to
    console.log("fetchNextPage", endpoint, url);
    fetchData(url);
  }

  const fetchNextPage = () => {
    setPage(page => page + 1);
    const url = utils.updateUrlParameter(endpoint, "_page", page); //this the full local url. we need to extract search params and set it to
    console.log("fetchNextPage", endpoint, url);
    fetchData(url);
  }

  const fetchAllRecords = () => {
    const fileName = "records" + gridName + ".csv";
    fetchAll(fileName);
  }

  // check if name is in the tab actions
  const isActionAllowed = function (name) {
    var iconName = name;
    if (allowedActions) {
      //now, let's loop on the allowedActions and see if we have it in the array.
      for (var i = 0; i < allowedActions.length; i++) {
        if (allowedActions[i].name == iconName) {
          return true
        }
      }
    }
    return false;
    // return true
  }
  const onCellClick = useCallback((e, data) => {
    const item = e;
    if (item.rowType == 'header') {
      const field = item.column.dataField;
      console.log("onCellClick", field, item);
      utils.urlOrderBy(field, true);
    } else if (item.rowType == 'data') {

      const field = item.column.dataField;
      const BI_DETAILS = "";//window._env_.REACT_APP_BI_DETAILS || process.env.REACT_APP_BI_DETAILS;
      if (field == BI_DETAILS) {
        console.log("onCellClick", field, item, data);
      }
    }
  }, []);

  const onExporting = useCallback((e) => {
    console.log("onExporting", e);
    if (e.format === 'xlsx') {
      const defaultFilename = utils.capitalize(gridName + " records") + "." + e.format;
      let fileName = prompt('Please enter file name', defaultFilename);
      fileName = fileName.split('.').pop() == e.format ? fileName : fileName + "." + e.format;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        worksheet: worksheet,
        component: e.component,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
        });
      });
      e.cancel = true;
    }

    else if (e.format === 'csv') {
      const defaultFilename = utils.capitalize(gridName + " records") + "." + e.format;
      let fileName = prompt('Please enter file name', defaultFilename);
      fileName = fileName.split('.').pop() == e.format ? fileName : fileName + "." + e.format;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        worksheet: worksheet,
        component: e.component,
      }).then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
        });
      });
      e.cancel = true;
    }
  }, []);

  const onCellHoverChanged = useCallback((e) => {
    const blDebug = false;

    var item = e;
    const rowType = item.rowType;
    if (rowType == "data") {
      // console.log("onCellHoverChanged", e, item.column);

      if (item.column.allow_remote_filtering == true) {
        let el = item.cellElement;
        let i = document.getElementById("dgfilter_icon");
        if (i == undefined) { return; }
        i.setAttribute("style", "display:none!important;z-index:-10");

        const rect = el.getBoundingClientRect();
        i.setAttribute("style", "z-index:10");
        i.style.display = 'block';
        i.style.position = 'fixed';
        i.style.top = (rect.top - Math.round(rect.height / 2) + 17) + 'px';
        i.style.left = (rect.right - (rect.width * 0.05)) + 'px';

        i.setAttribute("field_name", (item.column.dataField));
        i.setAttribute("field_type", (item.column.dataType));
        i.setAttribute("field_caption", (item.column.caption));
        i.setAttribute("field_value", (item.value));

        let ifilterNumber = document.getElementById("dgFilter_number");
        let ifilterText = document.getElementById("dgFilter_text");
        let ifilterDefault = document.getElementById("dgFilter_default");

        ifilterNumber.setAttribute("style", "display:none!important");
        ifilterText.setAttribute("style", "display:none!important");
        ifilterDefault.setAttribute("style", "display:none!important");

        if (item.column.dataType == 'string') {
          ifilterText.setAttribute("style", "display:unset!important");
        } else if (item.column.dataType == 'number') {
          ifilterNumber.setAttribute("style", "display:unset!important");
        } else {
          ifilterDefault.setAttribute("style", "display:unset!important");
        }
      }
    }
  }, []);


  const onEditorPrepared = useCallback((e) => {

    let i = document.getElementById(e.id);
    if (i != undefined) {
      const isNewRow = !!e.row.isNewRow;
      let x = document.querySelectorAll('[for="'+e.id+'"]');
      console.log("onEditorPrepared", e, isNewRow , i, x );
      switch (e.dataType) {
        case "file":
          i.setAttribute("type", "file");
          i.setAttribute("name", e.dataField);
          return;
        case "password":
          i.setAttribute("type", "password");
          if (isNewRow && !e.allow_adding) {
            //hide it
            i.setAttribute("style", "display:none!important");
            x[0].setAttribute("style", "display:none!important");
          } else if (!isNewRow && !e.allow_editing) {
            // hide it
            i.setAttribute("style", "display:none!important");
            x[0].setAttribute("style", "display:none!important");
          }
          return;
      }
    }
  });

  // const isEditorPreparing = useRef(false);
  // let editRow = null;
  const onEditorPreparing = useCallback((e) => {

    if (e.row && e.row.rowType === "data") {
      console.log("onEditorPreparing e", e, e.row,e.editorOptions);
      const isNewRow = !!e.row.isNewRow; //the double ! is to set it to false if it was undefined (i.e. raw is editing)
      if (isNewRow) {
        utils.setPageTitleElement(formTitle, "Add new record");
        e.editorOptions.disabled = (!e.allow_adding);
        e.editorOptions.readOnly = (!e.allow_adding);
      } else {
        utils.setPageTitleElement(formTitle, "Edit record");
        e.editorOptions.disabled = (!e.allow_editing);
        e.editorOptions.readOnly = (!e.allow_editing);
      }
    }
  }, []);



  const setFilterPopOver = (item) => {
    const operator = item.itemData.operator;
    const id = "dgfilter_icon";//item.target.id;
    const i = document.getElementById(id);
    const field_name = i.getAttribute("field_name");//, JSON.stringify(item.column.dataField));
    const field_value = i.getAttribute("field_value");//, JSON.stringify(item.value));
    const newLoc = utils.filterByField(field_name, operator, field_value);
    window.location.reload();
    const url = utils.ProcessPath(newLoc);
    const navops = utils.getNavOps("", url.search, url.hash);
    console.log("xxx", newLoc, url, navops);
    // do the actual navigation
    navigate(navops, {
      replace: true,
    });
  }


  const setColumn = (column) => {
    var endpoint = "" + column.lookup;
    const item = {
      id: column.id || 0,
      allow_remote_filtering: column.allow_remote_filtering || true,
      key: column.dataField || '',
      dataField: column.dataField,
      caption: column.allow_reading ? utils.capitalize(column.caption) : '',
      visible: column.visible,
      dataType: column.dataType,
      format: column.format,
      alignment: column.alignment,
      allow_reading: column.allow_reading || true,
      allow_adding: column.allow_adding || false,
      allow_editing: column.allow_editing || false,
      allow_filtering: column.allow_filtering || true,
      allow_search: column.allow_search || true,
      editor_type: column.editor_type || null,
      lookup_endpoint: endpoint || null,
      display_order: column.display_order || 0,
      fixed: column.fixed || false,
    };
    return item;
  }

  const cellRenderFunc = (item) => {
    // console.log("cellRenderFunc", item.rowType, item, item.column);
    if (!item.column) return;
    if (item.rowType != "data") return;

    var value = "";
    const column = item.column;
    if (column.dataField == "__index") {
      value = cellRenderIndex(item);
      // } else if (column.dataField == process.env.REACT_APP_BI_DETAILS) {
      //   value = cellRenderDetailsLink(item);
    } else {
      switch (column.dataType) {
        case 'datetime':
          value = cellRenderDatetime(item); break;
        case 'date':
          value = cellRenderDatetime(item); break;
        case 'timestamp':
          value = cellRenderTimestamp(item); break;
        case 'flag':
          value = cellRenderFlag(item); break;
        case 'link':
          value = cellRenderLink(item); break;
        case 'popup':
            value = cellRenderPopup(item); break;          
        case 'tags':
          value = cellRenderTags(item); break;
        case 'status':
          value = cellRenderStatus(item); break;
        case 'commercial':
          value = cellRenderCommercial(item); break;
        case 'duration':
          value = cellRenderDuration2Time(item); break;
        case 'number':
          value = cellRenderNumber(item); break;
        // case 'tags':
        //   value = cellRenderTags(item); break;
        default: value = cellRenderDefault(item); break;
      }
    }
    return (<span className={'grid-cell' + (column.allow_remote_filtering ? '-filterable' : '')}>{value}</span>);
  }

  const cellRenderTags = (item) => {
    if (!item.column) return;

    const field = item.column.dataField;
    const data = item.data[field];

    const tags = data ? data.split(",") : [];
    // let's split data 
    const rows = [];
    for (let i = 0; i < tags.length; i++) {
      const spanId = "filter_col_" + item.column.dataField + "_" + item.column.index + "_" + item.row.loadIndex + "_" + i;
      rows.push(<div className="dx-tag" key={spanId} ><div className="dx-tag-content cursor-none!important"><span>{tags[i]}</span></div></div>);
    }

    return (
      <div>
        {rows}
      </div>
    )


  }

  const cellRenderDefault = (item) => {
    if (!item.column) return;

    const field = item.column.dataField;
    const data = item.data[field];
    const spanId = "filter_col_" + item.column.dataField + "_" + item.column.index + "_" + item.row.loadIndex;
    return (
      <div id={spanId} className='inline'>{data}</div>
    )
      ;
  }

  const cellRenderFlag = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const data = item.data[field];
    const country = utils.getCountry(data);

    return (<><span className={"fi fis fi-" + country.alpha2.toLowerCase()} title={country.name}></span> {country.name}</>);
  }

  // this render takes the value (e.g. xx:xx:xx)
  // and make a link with the details
  const cellRenderLink = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const data = item.data[field];
    if (utils.isEmpty(data)) return;
    // split data with : as delimiter
    const data_details = data.split(":");

    // "users_link": "/admin/operators/19/users::fa fa-users:"
    const link = data_details[0] ? data_details[0] : "#";
    const caption = data_details[1] ? data_details[1] + " " : "";
    const icon = <i className={data_details[2] ? data_details[2] : (data_details[1]?"":"fa fa-file-text-o")} aria-hidden="true"></i>;
    const target = data_details[3] ? data_details[3] : "_self";
    return <span className='dx-link dx-link-edit dx-icon dx-link-icon center'><a href={link} target={target}>{caption}{icon}</a></span>;
  }

  const [popupVisible, setPopupVisible] = useState(false);
  const [userItem, setUserItem] = useState(false);
  const [saveEndpoint, setSaveEndpoint] = useState("");
  const [saveMethod, setSaveMethod] = useState("");
  const togglePopup = (item,link,method) => {
    console.log("togglePopup",item,link,method);
    // '/me/password'
    // const endpoint = "";
    setSaveEndpoint(link);
    setSaveMethod(method);
    setUserItem(item);
    setPopupVisible(true);
  }

  const cellRenderPopup = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const data = item.data[field];
    if (utils.isEmpty(data)) return;
    // split data with : as delimiter
    const data_details = data.split(":");

    // "users_link": "/admin/operators/19/users::fa fa-users:"
    // "xxx_popup": <endpoint>:<caption>:<icon>:<method>
    const link = data_details[0] ? data_details[0] : "#";
    const caption = data_details[1] ? data_details[1] + " " : "";
    const icon = <i className={data_details[2] ? data_details[2] : (data_details[1]?"":"fa fa-file-text-o")} aria-hidden="true"></i>;
    const method = data_details[3] ? data_details[3] : "post";
    return (
    <span className="link text-sm text-gray-800 dark:text-white hover:dark:text-white" onClick={()=>{togglePopup(item.data,link,method)}}>
      {caption} {icon}
    </span>)
  }

  const cellRenderCommercial = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const data = item.data[field];
    return <>{data}</>;
  }

  const cellRenderDuration2Time = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const orgvalu = item.data[field];
    let val = new Date(orgvalu * 1000).toISOString().substring(11, 19);
    return <span title={orgvalu + " seconds"}>{val}</span>;
  }

  const cellRenderNumber = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    var data = item.data[field]
    data = data ? data.toLocaleString(undefined, { maximumFractionDigits: 2 }) : data;
    return (<>{data}</>);
  }

  const cellRenderIndex = (item) => {
    return <span>{item.row.loadIndex + 1}</span>;
  }

  const cellRenderDatetime = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const data = item.data[field];
    return (
      data > 0 ?
        <Moment unix fromNow titleFormat="YYYY-MM-DD HH:mm:ss" withTitle>
          {data}
        </Moment>
        : <></>
    )
  }

  const cellRenderTimestamp = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const data = item.data[field];
    console.log("cellRenderTimestamp", item);
    return (
      data > 0 ?
        <Moment unix fromNow titleFormat="YYYY-MM-DD HH:mm:ss" withTitle>
          {data}
        </Moment>
        : <></>
    )
  }

  const cellRenderStatus = (item) => {
    if (!item.column) return;
    const field = item.column.dataField;
    const data = item.data[field];

    return (
      data == 1 ?
        <i className="fa-solid fa-toggle-on text-green-600"></i>
        :
        <i className="fa-solid fa-toggle-off text-gray-600"></i>
    )
  }


  // const headerCellRenderFunc = (item) => {
  //   if (!item.column) return;

  //   const field = item.column.dataField;
  //   const data = item.data[field];
  //   const spanId = "filter_col_" + item.column.dataField + "_" + item.column.index + "_" + item.row.loadIndex;
  //   return (
  //     <div id={spanId} className='inline'>{data}</div>
  //   )

  //     ;
  // }

  const headerCellRenderFunc = (item) => {
    // console.log("headerCellRenderFunc", item);
    if (!item.column) return;

    const data = item.column.caption;
    const field = item.column.dataField;

    const blFiltered = utils.isFieldFiltered(field); // true|false
    const sorted = utils.isFieldSorted(field); //null|asc|desc

    // console.log("headerCellRenderFunc isField all ", data, field, blFiltered, sorted);

    return (
      <span className={'grid-header ' + (blFiltered || sorted ? 'active' : '')}>
        {blFiltered ? <i className={"fa fa-filter filtered-header "} title="records are filtered by this column" /> : ' '}
        {sorted ? <i title="records are sorted by this column" className={" filtered-header fa fa-sort-alpha-" + sorted} /> : ' '}  {data}
      </span>);
  }

  const getCommandButtonIcon = (name, e) => {
    var iconName = name;
    if (allowedActions) {
      //now, let's loop on the allowedActions and see if we have it in the array.
      for (var i = 0; i < allowedActions.length; i++) {
        if (allowedActions[i].name == iconName) {
          console.log("isIcon", iconName, e, allowedActions[i]);
          return allowedActions[i].icon;
        }
      }
    }
    return "";
    // return "fa fa-trash-o";
  }


  const renderCommandButton = (item) => {
    var key = [];
    var buttons = [];
    var links = [];
    var popups = [];

    for (key in item.data) {
      if (key.substring(0, 2) == "__") {

        if (this.isActionAllowed(key)) {

          // these are the special, built-in actions that will be handled differently
          if (key == BI_ADD) { continue; }

          if (this.isCommandButtonVisible(item.data, key)) {
            const actionItem = this.getActionItem(key);
            const type = actionItem.type;

            //let's get the key type
            switch (type) {
              case consts.action_types.Button:
                buttons.push({
                  display_order: actionItem.display_order,
                  action: key,
                  icon: this.getCommandButtonIcon(key),
                  disabled: this.isCommandButtonDisabled(item.data, key)
                }); break;

              // case consts.action_types.Link:
              //   links.push({
              //     display_order: actionItem.display_order,
              //     path: utils.resolvePathVars(actionItem.path, item.data),
              //     icon: this.getCommandButtonIcon(key),
              //     disabled: this.isCommandButtonDisabled(item.data, key)
              //   }); break;
              case consts.action_types.Popup:
                popups.push({
                  display_order: actionItem.display_order,
                  action: key,
                  icon: this.getCommandButtonIcon(key),
                  disabled: this.isCommandButtonDisabled(item.data, key)
                }); break;
              default:
                console.log("unknown type", type); break;
            }
          }
        }
      }
    }

    buttons.sort((a, b) => (a.display_order > b.display_order) ? -1 : 1);
    // links.sort((a, b) => (a.display_order > b.display_order) ? -1 : 1);
    popups.sort((a, b) => (a.display_order > b.display_order) ? -1 : 1);

    return (
      <>
        {buttons.map((key, i) => (
          <span key={"button" + i}
            className={'dx-link dx-link-edit dx-icon action-button ' + key.icon + (key.disabled ? ' disabled' : ' link')}
            onClick={e => this.onCommandClick(e, key.action, item.data)}>&nbsp;</span>
        ))}

        {popups.map((key, i) => (
          <>
            <span key={"popup" + i}
              className={'red dx-link dx-link-edit dx-icon action-button ' + key.icon + (key.disabled ? ' disabled' : ' link')}
              onClick={e => this.showActionPopup(e, key.action, item.data)}>&nbsp;</span>
          </>
        ))}

        {/* {links.map((key, i) => (
          <a href={"/" + key.path} key={"link" + i}>
            <i className={'action-link ' + key.icon} />
          </a>
        ))} */}

      </>
    );

  }


  const showCommandButtons = () => {
    return false;
  }


  const onDateRangeChange = (e) => {
    if (!e || !e.length) return;

    let st = e[0];//.selection.startDate;
    let et = e[1];//.selection.endDate;

    const start_date = moment(st).format("YYYYMMDD");
    const end_date = moment(et).format("YYYYMMDD");

    setStartDate(start_date);
    setEndDate(end_date);

    utils.updateUrlParameter("_start", startDate);
    utils.updateUrlParameter("_end", endDate);

    // set the params and reload page
    window.location.reload();
  }

  const getAction = (name) => {
    if (allowedActions) {
      //now, let's loop on the allowedActions and see if we have it in the array.
      for (var i = 0; i < allowedActions.length; i++) {
        if (allowedActions[i].name == name) {
          return allowedActions[i];
        }
      }
    }
  }

  const onSaved = (event) => {
    console.log("onSaved", event);
  }

  const onSaving = (event) => {
    console.log("onSaving", event, event.target);


    // if nothing changes, event.changes is empty
    if (utils.isEmpty(event.changes)) return;

    const formData = new FormData();
    // -- if we're calling onSaving ---

    const changes = event.changes[0].data;
    const record = event.changes[0].key;
    const actionType = "__" + event.changes[0].type; //__insert, __update, __remove
    const data = utils.merge_options(changes, record);


    // ----
    // // if we're calling OnRawInserted
    // const data = event.data;
    // const record = event.key;
    // const actionType = BI_ADD;

    const resolvedEndPoint = resolveEndPoint(allowedActions, actionType, data);
    const action = getAction(actionType);

    if (actionType == "__remove") {

      // remove
      api.method(action.method, resolvedEndPoint, data, (response) => {
        console.log("executeCommand responce", response.data);
        if (response.meta.status == 200) {
          const msg = "operation done successfully";
          api.showMessage(msg, 'success', 'top center');
          }
      })

      return;
    }

    //first, we need to loop over the columns to see if we have a "file""
    // if so, then we set the contetn-types = multipart"
    // otherwise, json
    var isFileUpload = false;
    Object.entries(data).map(([key, value]) => {
      // here, we need to check if they key belongs to a "file" column
      columnsData.map((column, i) => {
        if (column.dataField == key) {
          if (column.dataType == 'file') {
            const fileInput = document.getElementsByName(key);
            const file = fileInput[0].files[0];//event.target.files[0];
            formData.append(key, file);
            isFileUpload = true;
          } else {
            formData.append(key, value);
          }
        }
      })
    });

    if (isFileUpload) {
      api.service.defaults.headers.common['Content-Type'] = "multipart/form-data";
      api.method(action.method, resolvedEndPoint, formData, (response) => {
        console.log("executeCommand responce", response.data);
        if (response.meta.status == 200) {
          const msg = "operation done successfully";
          api.showMessage(msg, 'success', 'top center');
          }

      });
    } else {
      // submit json data
      api.service.defaults.headers.common['Content-Type'] = 'application/json';
      api.method(action.method, resolvedEndPoint, data, (response) => {
        console.log("executeCommand responce", response.data);
        if (response.meta.status == 200) {
          const msg = "operation done successfully";
          api.showMessage(msg, 'success', 'top center');
          }

      });
    }
  };


  const resolveEndPoint = (allowedActions, actionName, data) => {
    for (var i = 0; i < allowedActions.length; i++) {
      if (allowedActions[i].name == actionName) {
        const action = allowedActions[i];
        let resolvedEndPoint = action.endpoint;
        // first resolve by URL params

        let resolvVars = utils.merge_options(urlParams, data);
        resolvedEndPoint = utils.stringFromTemplate(resolvedEndPoint, resolvVars);

        console.log("executeCommand resolvedEndPoint", action.endpoint, resolvedEndPoint, urlParams, data, resolvVars);

        // here we need to construct the reques
        return resolvedEndPoint;
      }
    }
  }

  // const allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];

  const getEditFun = (column) => {

    switch (column.dataType) {
      case 'tags':
        if (!utils.isEmpty(column.lookup_endpoint)) {
          return ColumnTagBox;
        } else { return undefined }
      case 'status':
        return ColumnSwitchBox;
      default:
        return undefined;
    }
  }

  const htmlEditorOptions = {
    height: "250",
    toolbar: {
      items: ["bold", "italic", "underline"]
    }
  };

  // for htmleditor fields
  // https://supportcenter.devexpress.com/ticket/details/t828510/devextreme-datagrid-how-to-display-htmleditor-in-the-form-editing-mode#React/src/App.js
  return (
    <div>
      <DataGrid
        // dataSource={dataSource}
        className='data-grid-x'
        dataSource={tableData}
        key={"dg" + Date.now()}
        // height={'81vh'}
        height={'79vh'}
        onEditorPreparing={onEditorPreparing}
        onEditorPrepared={onEditorPrepared}

        showColumnHeaders={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        columnAutoWidth={true}

        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}

        onCellClick={onCellClick}
        onExporting={onExporting}

        onCellHoverChanged={onCellHoverChanged}
        onSaving={onSaving}
      >

        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Sorting mode="none" />
        <Scrolling         mode="virtual"          />
        <Export enabled={true} formats={exportFormats} texts={exportTexts} />

        <Editing
          mode="popup"
          allowUpdating={isActionAllowed(BI_EDIT)}
          allowAdding={isActionAllowed(BI_ADD)}
          allowDeleting={isActionAllowed(BI_DELETE)}
        >
          <Popup showTitle={true} width={700} height={525} title={formTitle} />
          <Form labelMode="outside" stylingMode="outlines" height={"auto"} colCount={1} >
            {columnsData != undefined ? columnsData.map((item) => {
              const column = setColumn(item);
              if (column != undefined) {

                // if it's not allowed to be added or edited, then skip that column from the item list.
                if (!(column.allow_adding || column.allow_editing)) return;

                return (
                  <Item
                    stylingMode="outlined"
                    labelMode="outside"
                    id={column.id}
                    allow_remote_filtering={column.allow_remote_filtering}
                    key={column.key}
                    dataField={column.dataField}
                    dataType={column.dataType}
                    format={column.format}
                    editorType={column.editor_type}
                    allow_reading={column.allow_reading}
                    allow_adding={column.allow_adding}
                    allow_editing={column.allow_editing}
                    editorOptions={column.editor_type=='dxHtmlEditor'?htmlEditorOptions:null}
                  />
                );
                // }
              }
            }) : <></>}
          </Form>
        </Editing>

        {columnsData != undefined ? columnsData.map((item) => {
          const column = setColumn(item);
          console.log("rawColumns", column.dataField, lookupData);

          if (column != undefined) {
            return (
              <Column
                id={column.id}
                allow_remote_filtering={column.allow_remote_filtering}
                key={column.key}
                dataField={column.dataField}
                caption={column.caption}
                stylingMode="outlined"
                labelMode="outside"
                dataType={column.dataType}
                format={column.format}
                alignment={column.alignment}
                visible={column.visible}
                allow_reading={column.allow_reading}
                allow_adding={column.allow_adding}
                allow_editing={column.allow_editing}
                allow_filtering={column.allow_filtering}
                allow_search={column.allow_search}
                lookup_endpoint={column.lookup_endpoint}
                display_order={column.display_order}
                cellRender={cellRenderFunc}
                // onCellClick={onCellClick}
                headerCellRender={headerCellRenderFunc}
                fixed={column.fixed}
                fixedPosition={"right"}
                editCellComponent={getEditFun(column)}
              >
                {!utils.isEmpty(column.lookup_endpoint) ?
                  <Lookup
                    dataSource={lookupData ? lookupData[column.dataField] : null}
                    displayExpr="item_value"
                    valueExpr="item_id"
                  />
                  : <></>}
              </Column>
            );
          }
        }) : <></>}


        <Column type="buttons" fixed={true} caption={utils.capitalize("actions")} name="basicCommands" visible={isActionAllowed(BI_EDIT) || isActionAllowed(BI_DELETE)}>
          <GrdButton name="edit" hint="Edit item" icon={getCommandButtonIcon(BI_EDIT)} />
          <GrdButton name="delete" hint="Delete item" key="delete" icon={getCommandButtonIcon(BI_DELETE)} />
        </Column>
        <Column type="buttons" fixed={false} name="extraCommands" cellRender={renderCommandButton} visible={showCommandButtons()}>
        </Column>

        <Summary>
          <TotalItem
            column={columnsData != undefined && columnsData.length > 1 ? columnsData[0] : ""}
            summaryType="count"
            valueFormat={",##0.###"}
          />
        </Summary>

        <Toolbar className="grid_toolbar">
          <Item location="before" visible={isActionAllowed(BI_FILTER_DATE)}>
            Date Range <DateRangePicker appearance='default' ranges={consts.calendarRanges} onChange={onDateRangeChange} value={selectionRange} />
          </Item>
          {/* <Item location="after">
            Collected Items: <strong>{(columnsData ? columnsData.length : 0)}</strong> / page: <strong>{(page ? page : 1)}</strong>
          </Item> */}

          <Item location="after">
            <Button className="informer"
              title="load previous page"
              disabled={!allowPreviousPage()}
              icon='chevronprev'
              onClick={fetchPreviousPage}
            />
          </Item>

          <Item location="after">
            <Button className="informer"
              title="load next page"
              disabled={!allowNextPage()}
              icon='chevronnext'
              onClick={fetchNextPage}
            />
          </Item>
          <Item location="after">
            <Button className="informer"
              title="reset all fitlers"
              icon='repeat'
              disabled={!utils.filterIsSet()}
              onClick={() => utils.resetURLParams(true)}
            />
          </Item>

          {!isActionAllowed(BI_ADD) ? <></> : <Item name="addRowButton" />}

          <Item name="searchPanel" />
          <Item name="exportButton" visible={isActionAllowed(BI_EXPORT_GRID)} />
          <Item name="columnChooserButton" visible={false} />
          <Item >
            <Button className="informer"
              title="downlaod full records"
              disabled={!(columnsData && columnsData.length > 1) || !isActionAllowed(BI_DOWNLOAD_FULL)}
              icon='download'
              onClick={fetchAllRecords}
            />
          </Item>
        </Toolbar>
      </DataGrid>

      {/* <div id='dgfilter_icon' className='' aria-hidden="true">
        <TbFilter />
        <div id='dgfilter_filters' className="flex p-1 rounded-[4px]" aria-hidden="true">
          <div className="p-1">
            <div>
              <div id='dgFilter_text'>
                <ButtonGroup
                  items={consts.filterTextOperators}
                  keyExpr="operator"
                  stylingMode="outlined"
                  selectedItemKeys={selectedItemKeys}
                  onItemClick={setFilterPopOver}
                />
              </div>

              <div id='dgFilter_number'>
                <ButtonGroup
                  items={consts.filterNumberOperators}
                  keyExpr="operator"
                  stylingMode="outlined"
                  selectedItemKeys={selectedItemKeys}
                  onItemClick={setFilterPopOver}
                />
              </div>

              <div id='dgFilter_default'>
                <ButtonGroup
                  items={consts.filterDefaultOperators}
                  keyExpr="operator"
                  stylingMode="outlined"
                  selectedItemKeys={selectedItemKeys}
                  onItemClick={setFilterPopOver}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {popupVisible?<MyUserResetPasswordPopupComponent item={userItem} endpoint={saveEndpoint} method={saveMethod} container=".app_main" popupVisible={popupVisible} onHide={()=>{setPopupVisible(false)}} />:<></>}
    </div>
  );
};

export default ComplexTable;