/* eslint-disable */
import { useNavigate } from "react-router-dom";
import React, { useCallback, useState } from 'react';
import { HiX } from "react-icons/hi";
import TreeView from 'devextreme-react/tree-view';
import * as utils from '../../utils/utils.js'
import logo from "assets/img/logo.png";
const Sidebar = ( { open, onClose,menuItems }) => {

  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState();

  const selectItem = useCallback(
    (e) => {
      if (e.itemData.path == "") {
        menuItems.map((item) => {
          if (item.path == window.location.pathname) {
            setCurrentItem(item);
            navigateToItem(item);
            return;
          }
        });
        return;
      }

      e.itemData.selected = true;
      setCurrentItem({ ...e.itemData });
      navigateToItem(e.itemData);
    },
    [setCurrentItem],
  );

  const navigateToItem = (currentItem) => {
    if (currentItem == undefined) return; //cannot click
    if (currentItem.path !== '#') {
      const url = utils.ProcessPath(currentItem.path);
      nagivateToPath(url.pathname, url.search, url.hash);
    }
  }

  // do the actual navigation
  const nagivateToPath = (path, qs, hsh) => {
    const navops = utils.getNavOps(path,qs,hsh);
      navigate(navops, {
        replace: true,
      });
      // window.location.reload();
  };

  const itemTemplate = (item) => {
    let isSelected = false;

    if (currentItem == undefined || currentItem.path == "") {
      menuItems.map((item) => {
        if (item.path == window.location.pathname) {
          setCurrentItem(item);
          // return;
          // isSelected = (item.id == currentItem.id);
          isSelected = true;
        }
      });  
    }
 

    // // here is a basic logic to check if item is selected based on path
    // // we need to expand this to cover cases with qs, hash ...etc
    // if (currentItem == undefined) {
    // };

    if (currentItem != undefined && item != undefined) {
      isSelected = (item.id == currentItem.id);
    } else {
      // let's check if we can map current path to an item.
      menuItems.map((item) => {
        if (item.path == window.location.pathname) {
          setCurrentItem(item);
          isSelected = true;
        }
      });  
    }

    
    // let fontx = isSelected ? "font-bold" : "font-medium";
    let indicator = isSelected ? "<div class='absolute right-0 top-px h-9 w-1 rounded-lg bg-[#DC002B]' />" : "";
    return (
      // "<div className='" + fontx + "'>" + item.name + "</div>" + indicator
      "<div>" + item.name + "</div>" + indicator
    );
  }

  return (
    <div
      className={`overflow-y-auto h-full sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[50px] mt-[15px] flex items-center`}>
                <div className="inner-header-admin">
          <section className="logo">
            <a className="header_nav" href="https://www.gsma.com/">
              <img src={logo} className="inner-header-logo" alt="GSMA logo" />
            </a>
          </section>
          <nav className="main-nav" role="navigation">Call Check</nav>
        </div>

      </div>
      
      <div className="mt-[1px] mb-1 h-px" />

      <div className='pl-3'>
        <TreeView
          className='tree-view'
          id="side-nav-treeview"
          items={menuItems}
          dataStructure="plain"
          displayExpr="name"
          keyExpr="id"
          onItemClick={selectItem}
          itemTemplate={itemTemplate}
        />
      </div>
    </div>
  );
};

export default Sidebar;
