
import Footer from "components/footer/FooterAuthDefault";
import { Routes, Route } from "react-router-dom";
import SignIn from "views/auth/SignIn";
import logo from "assets/img/logo.png";
// import routes from "routes.js";
// import { predefinedPaths } from "utils/consts";

export default function Auth() {
  document.documentElement.dir = "ltr";
  return (
    <>
      {/* <div className="gsma-red-top-bar"></div> */}
      <header className="gsma-header gsma-logo-header" role="banner">
        <div className="inner-header">
          <section className="logo">
            <a className="header_nav" href="https://www.gsma.com/">
            <img src={logo} alt="GSMA logo" />
            </a>
          </section>
          <nav className="main-nav" role="navigation">| Call Check</nav>
        </div>
      </header>

      <main className={`mx-auto min-h-screen max-h-screen`}>
        <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
          <section className=" min-h-screen flex items-center justify-center">
            <Routes>
              <Route path="/*" element={<SignIn />} />
            </Routes>
          </section>
        </div>
      </main>

      <footer className="footer">
        <div>
          <span>© Copyright {1900 + new Date().getYear()} GSM Association.</span><span> The GSM logo is registered and owned by the GSM Association.</span> | <span>Powered by <a href="https://www.oculeus.com" target="_blank">Oculeus GmbH</a>.</span>
        </div>
        <div>
          <nav className="nav">

          </nav>
        </div>
      </footer>
    </>
  );
}
