
import React from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'fontawesome-4.7/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";

import { Routes, Route } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
const storage = sessionStorage

const App = () => {

  return (
    <div className="app_main">
      <Routes>
        { //check if we have access token, otherwise, show login
        storage.getItem('token') ? 
        <Route path="/*" element={<AdminLayout />} /> 
        : 
        <Route path="/*" element={<AuthLayout />} />
        }
      </Routes>
    </div>
  );
};

export default App;
