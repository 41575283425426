import React, { useState } from 'react';
import VectorMap, {
    ControlBar,
    Layer,
    Source,
    Tooltip,
} from 'devextreme-react/vector-map';
import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';
import * as utils from "utils/utils";
import api from 'api';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { DataGrid } from 'devextreme-react';
// import { Export, Label, Range, RangeContainer, Scale } from 'devextreme-react/circular-gauge';
import PieChart, {

    Label,
    SmallValuesGrouping,
    Export,
} from 'devextreme-react/pie-chart';

import {
    Chart, Series, CommonSeriesSettings, Legend,
    ValueAxis, Title,
    ConstantLine,
} from 'devextreme-react/chart';
// import { Connector, Size } from 'devextreme-react/pie-chart';
// import { isEmpty } from 'utils/utils';

const GridItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


class TFNAnalyticsComponent extends React.Component {


    selectionRange = [];

    constructor(props) {
        super(props);
        this.state = {

            tab: this.props.item,

            start_date: utils.getStartDate(),
            end_date: utils.getEndDate(),

            data: [], //this.props.item.data || [],
            fields: [], //this.props.item.fields || [],

            hasMore: true,
            hasData: true,
            page: 1,
            _isLoading: false,
            showMap: false,

            // _week_pattern
            dataWeekPattern: [],
            // _country_counts
            dataCountryCounts: [],
            dataCountryCountsFields: [],
            dataCountryMapCounts: [],
            // _response
            dataResponseCounts: [],
        };

        // data collection and exporting
        this.fetchData = this.fetchData.bind(this);
        this.setGridData = this.setGridData.bind(this);
        // this.cellRenderFlag = this.cellRenderFlag.bind(this);

    }

    componentDidMount() {

        console.log("componentDidMount");
        this.state.page = 1;
        utils.updateUrlParameter("_page", this.state.page);

        this.state.start_date = utils.getStartDate();
        this.state.end_date = utils.getEndDate();

        utils.updateUrlParameter("_start", this.state.start_date);
        utils.updateUrlParameter("_end", this.state.end_date);

        this.selectionRange.push(utils.getStartDate(true));
        this.selectionRange.push(utils.getEndDate(true));

        this.fetchData(); //get initial batch
        this.setGridData();

    }

    fetchData() {
        if (!this.state._isLoading && this.state.tab.options) {
            console.log("xxxx", this.state.tab.options);
            this.state.tab.options.forEach((option) => {
                // console.log("xxxx", this.state.tab.options, option.name, option.value);
                var optName = option.name;
                var endpoint = option.value;
                api.get(endpoint, null, (response) => {
                    const newItems = response.data;
                    if (response.meta.status == 200) {
                        switch (optName) {
                            case "_week_pattern":
                                this.setState({ dataWeekPattern: newItems });
                                break;
                            case "_country_counts":
                                response.fields.sort((a, b) => (a.display_order < b.display_order) ? -1 : 1);
                                this.setState({
                                    dataCountryCounts: newItems,
                                    dataCountryCountsFields: response.fields
                                });
                                this.state.dataCountryCounts = newItems;
                                this.setState({ dataCountryMapCounts: newItems });
                                this.state.dataCountryMapCounts = newItems;

                                this.state.showMap = (this.state.dataCountryMapCounts.length > 0);
                                break;
                            case "_response":
                                this.setState({ dataResponseCounts: newItems.slice(0, 5) });
                                break;
                        }

                    }
                });
            });

        }
    }


    customizeLayer = (elements) => {
        elements.forEach((element) => {
            const items = this.state.dataCountryCounts;
            const iso = element.attribute('iso_a2');
            items.forEach(function (item) {
                // const populationx = (getCountry(this.state.dataCountryMapCounts, element.attribute('iso_a2'))||0);//randomIntFromInterval(1, 100);
                if (item.org_iso == iso) {
                    // const percentagex = item.allowed_percentage;
                    const percentagex = (item.allowed / (item.allowed + item.denied)) * 100;
                    console.log("customizeLayer item x", item.org_iso, iso, item.allowed_percentage);
                    const populationx = percentagex;
                    element.attribute('population', populationx);
                }
                // return 0;
            })
            // const populationx = (getCountry(this.state.dataCountryMapCounts, element.attribute('iso_a2'))||0);//randomIntFromInterval(1, 100);
            // element.attribute('population', populationx);
            // console.log("customizeLayer item", element, populationx, element.attribute('iso_a2'));
        });
    }

    // // gridDataSource = _country_counts
    // gridDataSource = [
    //     { "country": "China", "percentage": 99, "allowed": 69243, "denied": 6924 },
    //     { "country": "India", "percentage": 97.4, "allowed": 69243, "denied": 6924 },
    //     { "country": "United States", "percentage": 24.44, "allowed": 69243, "denied": 6924 },
    //     { "country": "Indonesia", "percentage": 23.45, "allowed": 69243, "denied": 6924 },
    //     { "country": "Brazil", "percentage": 29.83, "allowed": 69243, "denied": 6924 },
    //     { "country": "Pakistan", "percentage": 22.62, "allowed": 69243, "denied": 6924 },
    //     { "country": "Nigeria", "percentage": 72.42, "allowed": 69243, "denied": 6924 },
    //     { "country": "Bangladesh", "percentage": 82.18, "allowed": 69243, "denied": 6924 },
    //     { "country": "Russia", "percentage": 52.04, "allowed": 69243, "denied": 6924 },
    //     { "country": "Japan", "percentage": 91.77, "allowed": 69243, "denied": 6924 },
    //     { "country": "Mexico", "percentage": 81.67, "allowed": 69243, "denied": 6924 },
    //     { "country": "Philippines", "percentage": 31.39, "allowed": 69243, "denied": 6924 },
    //     { "country": "Vietnam", "percentage": 81.25, "allowed": 69243, "denied": 6924 },
    //     { "country": "Ethiopia", "percentage": 51.23, "allowed": 69243, "denied": 6924 },
    //     { "country": "Egypt", "percentage": 81.21, "allowed": 69243, "denied": 6924 },
    //     { "country": "Germany", "percentage": 21.13, "allowed": 69243, "denied": 6924 },
    //     { "country": "Iran", "percentage": 81.08, "allowed": 69243, "denied": 6924 },
    //     { "country": "Turkey", "percentage": 21.07, "allowed": 69243, "denied": 6924 },
    //     { "country": "Congo (Kinshasa)", "percentage": 30.94, "allowed": 69243, "denied": 6924 },
    //     { "country": "France", "percentage": 30.92, "allowed": 69243, "denied": 6924 },
    //     { "country": "Thailand", "percentage": 30.9, "allowed": 69243, "denied": 6924 },
    //     { "country": "United Kingdom", "percentage": 80.89, "allowed": 69243, "denied": 6924 },
    //     { "country": "Italy", "percentage": 30.85, "allowed": 69243, "denied": 6924 },
    //     { "country": "Burma", "percentage": 30.84, "allowed": 69243, "denied": 6924 },
    //     { "country": "South Africa", "percentage": 30.74, "allowed": 69243, "denied": 6924 },
    //     { "country": "S. Korea", "percentage": 30.7, "allowed": 69243, "denied": 6924 },
    //     { "country": "Colombia", "percentage": 30.66, "allowed": 69243, "denied": 6924 },
    //     { "country": "Spain", "percentage": 60.65, "allowed": 69243, "denied": 6924 },
    //     { "country": "Tanzania", "percentage": 30.63, "allowed": 69243, "denied": 6924 },
    //     { "country": "Kenya", "percentage": 80.62, "allowed": 69243, "denied": 6924 },
    //     { "country": "Ukraine", "percentage": 30.6, "allowed": 69243, "denied": 6924 },
    //     { "country": "Argentina", "percentage": 30.59, "allowed": 69243, "denied": 6924 },
    //     { "country": "Algeria", "percentage": 50.54, "allowed": 69243, "denied": 6924 },
    //     { "country": "Poland", "percentage": 30.54, "allowed": 69243, "denied": 6924 },
    //     { "country": "Sudan", "percentage": 30.52, "allowed": 69243, "denied": 6924 },
    //     { "country": "Canada", "percentage": 90.49, "allowed": 69243, "denied": 6924 },
    //     { "country": "Uganda", "percentage": 30.49, "allowed": 69243, "denied": 6924 },
    //     { "country": "Iraq", "percentage": 30.47, "allowed": 69243, "denied": 6924 },
    //     { "country": "Morocco", "percentage": 30.46, "allowed": 69243, "denied": 6924 },
    //     { "country": "Uzbekistan", "percentage": 30.43, "allowed": 69243, "denied": 6924 }
    // ]
    //     ;

    // barDataSource = _week_pattern
    barDataSource = [{
        day: 'Monday',
        allowed: 11389,
        denied: 2156,
        ratio: 0,
    }, {
        day: 'Tuesday',
        allowed: 22349,
        denied: 3258,
        ratio: 0,
    }, {
        day: 'Wednesday',
        allowed: 33312,
        denied: 43210,
        ratio: 0,
    }, {
        day: 'Thursday',
        allowed: 44430,
        denied: 5467,
        ratio: 0,
    }, {
        day: 'Friday',
        allowed: 55331,
        denied: 65532,
        ratio: 0,
    }, {
        day: 'Saturday',
        allowed: 66132,
        denied: 76152,
        ratio: 0,

    }, {
        day: 'Sunday',
        allowed: 7780,
        denied: 8150,
        ratio: 0,
    }];

    // areas _response
    areas = [
        {
            perc: 0, record_count: 0, status_text: 'legitimate_call', status_code: '200',
        }, {
            perc: 0, record_count: 0, status_text: 'illegitimate_call', status_code: '406',
        }, {
            perc: 0, record_count: 0, status_text: 'call_status_not_determined', status_code: '404',
        }, {
            perc: 0, record_count: 0, status_text: 'peer_not_found', status_code: '404',
        }, {
            perc: 0, record_count: 0, status_text: 'junk_traffic_src', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'junk_traffic_dst', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'ato_traffic', status_code: '200',
        }, {
            perc: 0, record_count: 0, status_text: 'att_traffic', status_code: '200',
        }, {
            perc: 0, record_count: 0, status_text: 'afa_traffic_local', status_code: '200',
        }, {
            perc: 0, record_count: 0, status_text: 'afa_traffic_remote', status_code: '200',
        }, {
            perc: 0, record_count: 0, status_text: 'dno_traffic_local', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'dno_traffic_remote', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'dnt_traffic_local', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'dnt_traffic_remote', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'irsf_traffic', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'scp_violation', status_code: '417',
        }, {
            perc: 0, record_count: 0, status_text: 'scp_no_violation', status_code: '200',
        }, {
            perc: 0, record_count: 0, status_text: 'scp_not_relevant', status_code: '200',
        }, {
            perc: 0, record_count: 0, status_text: 'afa_notallowed', status_code: '417'
        }
    ];


    customizeTooltip(arg) {
        if (arg.layer.type === 'marker') {
            return { text: JSON.stringify(arg.attribute('item')) };
        }
        return null;
    }


    customizeMapTooltip(arg) {
        if (arg.attribute('population')) {
            return {
                text: `${arg.attribute('name')} [${arg.attribute('iso_a2')}]: ${arg.attribute('population').toFixed(2)}% of calls allowed`,
            };
        }
        return null;
    };


    setAreaData() {
        var total = 0;
        this.areas.forEach(function (item) {
            item.record_count = Math.random() * 100;
            total = total + item.record_count;
        });
        this.areas.forEach(function (item) {
            item.perc = Math.round((item.record_count / total) * 100);
        });

        this.areas.sort((a, b) => (a.record_count > b.record_count) ? -1 : 1);
        // return (this.areas.splice(0,5));
        return (this.areas);
    };

    setGridData() {
        // console.log("setGridData",this.state.dataCountryCounts);

        this.state.dataCountryCounts.forEach(function (item) {
            item.allowed = item.allowed_count;
            item.denied = item.denied_count;
            item.percentage = item.allowed_percentage + "%";
        });
        return (this.state.dataCountryCounts);
    };



    render() {

        const colorGroups = [0, 20, 40, 60, 80, 100];

        const bounds = [-180, 85, 180, -60];
        const online = ['#DD0000', '#99D973'];
        // const gridDataX = this.setGridData();

        // const data = this.state.data; //.sort((a, b) => b.count - a.count);
        // const data = complaintsData.sort((a, b) => b.count - a.count);

        // const totalCount = data.reduce((prevValue, item) => prevValue + item.count, 0);
        // let cumulativeCount = 0;

        // var dataArray = data.map((item) => {
        //     return {
        //         is_online: item.is_online,
        //         coordinates: [item.lat, item.lon],
        //         attributes: {
        //             item: item,
        //             name: item.country,
        //         },
        //     };
        // });

        const maxHeight = "325px";
        const allowExport = false;

        // const dataRowTemplate = (item) => {
        //     console.log("dataRowTemplate", item);
        //   }
        
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <GridItem>
                        <h4>Countries</h4>
                        <DataGrid
                            dataSource={this.setGridData()}
                            columns={this.state.dataCountryCountsFields}
                            key={"dg" + Date.now()}
                            height={maxHeight}

                            allowColumnResizing={true}
                            columnResizingMode="widget"
                            columnAutoWidth={true}

                            showColumnLines={false}
                            showRowLines={true}

                            hoverStateEnabled={true}
                            // dataRowTemplate={dataRowTemplate}
                        >
                            <Export enabled={allowExport} />
                        </DataGrid>
                    </GridItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <GridItem>
                        <h4>World Map</h4>
                        {!this.state.showMap ?
                            <></>
                            :

                            <VectorMap

                                id="vector-map"
                                bounds={bounds}
                                zoomingEnabled={false}
                                height={maxHeight}
                            >
                                <ControlBar
                                    zoomVisible={false}
                                    panVisible={false}
                                />
                                <Layer
                                    dataSource={mapsData.world}
                                    name="areas"
                                    customize={this.customizeLayer}
                                    colorGroupingField="population"
                                    colorGroups={colorGroups}
                                // palette="Pastel"
                                >
                                </Layer>
                                <Legend customizeText={customizeText}>
                                    <Source
                                        layer="areas"
                                        grouping="color"
                                    ></Source>
                                </Legend>
                                <Tooltip
                                    enabled={true}
                                    customizeTooltip={this.customizeMapTooltip}
                                ></Tooltip>

                                <Export enabled={allowExport} />
                            </VectorMap>
                        }
                    </GridItem>
                </Grid>
                <Grid item xs={12} md={12}>
                    <GridItem>
                        <h4>Day-of-Week Pattern</h4>

                        <Chart
                            id="chart"
                            dataSource={this.state.dataWeekPattern}
                            height={maxHeight}
                        >
                            <CommonSeriesSettings argumentField="day_of_week" type="stackedBar" />
                            <Series
                                valueField="denied_count"
                                name="Denied"
                                color={online[0]}
                            />
                            <Series
                                valueField="allowed_count"
                                name="Allowed"
                                color={online[1]}
                            />

                            <Series
                                name="Allowed %"
                                valueField="allowed_percentage"
                                axis="percentage"
                                type="spline"
                                color="#6b71c3"
                            >
                                <Label visible={true} customizeText={customizePercentageText} />
                            </Series>

                            <ValueAxis position="left">
                                <Title text="calls" />
                            </ValueAxis>

                            <ValueAxis
                                name="percentage"
                                position="right"
                                tickInterval={20}
                                showZero={true}
                                valueMarginsEnabled={false}
                            >
                                <Label text="percentage" customizeText={customizePercentageText} />
                                <ConstantLine
                                    value={75}
                                    width={2}
                                    color="#fc3535"
                                    dashStyle="dash"
                                >
                                    <Label visible={true} text="acceptable atuhentication percentage" />
                                </ConstantLine>
                            </ValueAxis>
                            <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                itemTextPosition="top"
                            />
                            {/* <Export enabled={false} /> */}
                            <Tooltip
                                enabled={true}
                                location="edge"
                                shared={true}
                                contentRender={TooltipTemplate}
                            />
                            <Export enabled={allowExport} />
                        </Chart>
                    </GridItem>
                </Grid>
                <Grid item xs={12} md={12}>
                    <GridItem>
                        <h4>Response Codes (top 5)</h4>

                        <PieChart
                            id="pie"
                            dataSource={this.state.dataResponseCounts}
                            palette="Bright"
                        >
                            <Series
                                argumentField="call_status"
                                valueField="total_records"
                            >
                                <Label
                                    visible={true}
                                    customizeText={formatLabel}
                                    format="fixedPoint"
                                >
                                </Label>
                                <SmallValuesGrouping
                                    threshold={4.5}
                                    mode="smallValueThreshold"
                                />
                            </Series>
                            <Legend
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                                visible={false}
                            />
                            <Export enabled={allowExport} />
                        </PieChart>

                    </GridItem>
                </Grid>
            </Grid>
        );
    }
}



export default TFNAnalyticsComponent;

function customizeText(arg) {
    let text;
    if (arg.index === 0) {
        text = `< ${arg.end}%`;
    } else {
        text = `${arg.start}% to ${arg.end}%`;
    }
    return text;
}




function formatLabel(arg) {
    return `${arg.argumentText}: ${arg.valueText}%`;
}

// export default App;


// https://js.devexpress.com/Demos/WidgetsGallery/Demo/Gauges/PaletteForRanges/React/Light/
// import React from 'react';
// import {
//   CircularGauge, Scale, Label, RangeContainer, Range, Title, Font, Export,
// } from 'devextreme-react/circular-gauge';

// class App extends React.Component {

//   render() {
//   const online = ['#DD0000','#99D973'];
//   const colorGroups = [0, 20, 40, 60,80, 100];
//   return (
//   <CircularGauge
//     id="gauge"
//     value={57.2}
//   >
//     <Scale startValue={0} endValue={100} tickInterval={10}>
//       <Label useRangeColors={true} />
//     </Scale>
//     <RangeContainer palette={online}>
//       <Range startValue={0} endValue={20} />
//       <Range startValue={20} endValue={40} />
//                <Range startValue={40} endValue={60} />
//                 <Range startValue={60} endValue={80} />
//     <Range startValue={80} endValue={100} />
//     </RangeContainer>
//     <Title text="Outbound Calls">
//       <Font size={28} />
//     </Title>
//     <Export enabled={true} />
//   </CircularGauge>
//     );
//   }
// }

// export default App;



// https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/StackedBar/React/Light/
// import React from 'react';

// import {
//   Chart, Series, CommonSeriesSettings, Legend, Label, ValueAxis, Title, Export, Tooltip,   ConstantLine,
// } from 'devextreme-react/chart';
//   import PieChart, {
//   Series,
//   Tooltip,
//   Export,
// } from 'devextreme-react/pie-chart';

// import service from './data.js';

// const dataSource = service.getMaleAgeData();
// const online = ['#DD0000','#99D973'];

// class App extends React.Component {

//   render() {

//     return (
//       <Chart
//         id="chart"
//         title="Outbound Calls (Avg. Weekly)"
//         dataSource={dataSource}

//       >
//         <CommonSeriesSettings argumentField="day" type="stackedBar" />
//         <Series
//           valueField="denied"
//           name="Denied"
// 					color={online[0]}
//         />
//         <Series
//           valueField="allowed"
//           name="Allowed"
// 					color={online[1]}
//         />

// 			<Series
//           name="Percentage"
//           valueField="ratio"
//           axis="percentage"
//           type="spline"
//           color="#6b71c3"
//         >
//         <Label visible={true} customizeText={customizePercentageText}/>
//         </Series>

//         <ValueAxis position="left">
//           <Title text="calls" />
//         </ValueAxis>

// 			<ValueAxis
//           name="percentage"
//           position="right"
//           tickInterval={20}
//           showZero={true}
//           valueMarginsEnabled={false}
//         >         
//         <Label text="percentage" customizeText={customizePercentageText} />


//                   <ConstantLine
//             value={75}
//             width={2}
//             color="#fc3535"
//             dashStyle="dash"
//           >
//             <Label visible={true} text="acceptable atuhentication percentage"/>
//           </ConstantLine>

//         </ValueAxis>

//         <Legend
//           verticalAlignment="bottom"
//           horizontalAlignment="center"
//           itemTextPosition="top"
//         />
//         <Export enabled={false} />
//         <Tooltip
//           enabled={true}
//           location="edge"
// shared={true}
//          contentRender={TooltipTemplate}
//         />

//       </Chart>
//     );
//   }


function TooltipTemplate(info) {
    const itemSource = [
        {
            "name": "unauthenticated",
            "value": info.point.data.denied,
        }, {
            "name": "authenticated",
            "value": info.point.data.allowed
        },];
    return (
        <div className="state-tooltip">
            <div>
                <span className="caption">Allowed Calls</span>: <span className="area-km">{(info.point.data.allowed)} ({info.point.data.ratio}%)</span>
            </div>
            <div>
                <span className="caption">Denied Calls</span>: <span className="area-km">{(info.point.data.denied)}</span>
            </div>
        </div>
    );
}

function customizePercentageText({ valueText }) {
    return `${valueText}%`;
}
