
import React, { useEffect, useState } from 'react';
// import ComplexTable from "./components/ComplexTable";
// import api from 'api';
import * as utils from 'utils/utils.js'
import { useParams, useSearchParams } from 'react-router-dom';
import TFNAnalyticsComponent from './components/TFNAnalyticsComponent';


const GeneralDataTablePageView = (props) => {

  const useCustomSearchParams = () => {
    const [search, setSearch] = useSearchParams();
    const searchAsObject = Object.fromEntries(
      new URLSearchParams(search)
    );
  
    return [searchAsObject, setSearch];
  };

  // const { item, ...rest } = props;
  const { item } = props;
  const [gridCaption, setGridCaption] = useState("")
  const [resolvedEndPoint, setResolvedEndpoint] = useState("")
  
  const {...urlParams} = useParams();
  const [searchParams] = useCustomSearchParams();
  // const endpointVars = {client_id:client_id,feature_id:feature_id};
  // let endpointVars = {};
  // const setEndpointVars = (params) => {
  //   console.log("paramsxxx",params);
  // }

  // const fetchData = (endpoint) => {
  //   if (item != undefined) {
  //     if (endpoint !== "") {
  //       setGridCaption(item.name);
  //       api.get(endpoint, null, (response) => {
  //         const data = response.data;
  //         const columns = response.fields;
          // const actions = [
          //   {
          //     "description": "create new item",
          //     "display_order": 1,
          //     "endpoint": "/tfn/admin/nodes/",
          //     "icon": "fa fa-plus",
          //     "id": 13,
          //     "method": "POST",
          //     "name": "__allow_add",
          //     "path": "",
          //     "type": "Button"
          //   },
          //   {
          //     "description": "edit item",
          //     "display_order": 1,
          //     "endpoint": "/tfn/admin/nodes/{id}",
          //     "icon": "fa fa-pencil-square-o",
          //     "id": 14,
          //     "method": "PUT",
          //     "name": "__allow_edit",
          //     "path": "",
          //     "type": "Button"
          //   },
          //   {
          //     "description": "delete item",
          //     "display_order": 1,
          //     "endpoint": "/tfn/admin/nodes/{id}",
          //     "icon": "fa fa-trash-o",
          //     "id": 15,
          //     "method": "DELETE",
          //     "name": "__allow_delete",
          //     "path": "",
          //     "type": "Button"
          //   },
          //   {
          //     "description": "enable item",
          //     "display_order": 1,
          //     "endpoint": "/tfn/admin/nodes/{id}",
          //     "icon": "fa fa-toggle-on",
          //     "id": 16,
          //     "method": "POST",
          //     "name": "__allow_enable",
          //     "path": "",
          //     "type": "Button"
          //   },
          //   {
          //     "description": "disable item",
          //     "display_order": 1,
          //     "endpoint": "/tfn/admin/nodes/{id}",
          //     "icon": "fa fa-toggle-off",
          //     "id": 17,
          //     "method": "POST",
          //     "name": "__allow_disable",
          //     "path": "",
          //     "type": "Button"
          //   },
          //   {
          //     "description": "download full data records",
          //     "display_order": 1,
          //     "endpoint": "",
          //     "icon": "fa fa-plus",
          //     "id": 18,
          //     "method": "GET",
          //     "name": "__allow_download_full",
          //     "path": "",
          //     "type": "Button"
          //   }
          // ];//response.actions;
  //         setData(data);
  //         setColumns(columns);
  //         setActions(actions);

  //         console.log("data",data);
  //         console.log("columns",columns);
  //         console.log("actions",actions);
  //       });
  //     }
  //   }
  // }

  // // this will fetch data of specific page
  // const fetchPage = (page) => {
  //   //
  //   utils.updateUrlParameter("_page", page);
  // }

  // // this will get all data and save it in a csv file
  // const fetchAll = () => {
  //   //
  // }

// const converQS = (qs) => {
//   // qs.map((k,v) => {
//   //   console.log("item abc kv",k,v);
//   //   // return {k : v}
//   // });
//   return qs
// }
  useEffect(() => {
    // console.log("item abc", item.options,urlParams,searchParams);
    if (!item.options) return;
    item.options.map((option) => { //xx
      console.log("item option x2", option);  
      if (option.name == "endpoint") {
        // solve the endpoint vars with the URL passed params
        // e.g. /admin/clients/:client_id/features/:feature_id/peers will be resolved with {client_id = 1} & {feature_id = 2} values -> /admin/clients/1/features/2/peers

        let resolvedEndPoint = option.value;
        // first resolve by URL params
        resolvedEndPoint = utils.stringFromTemplate(resolvedEndPoint,urlParams);
        // then resolve by Querystring
        resolvedEndPoint = utils.stringFromTemplate(resolvedEndPoint,searchParams);
        
        if (resolvedEndPoint != "") {
          console.log("item option resolve", option.value,resolvedEndPoint);
          setResolvedEndpoint(resolvedEndPoint);
        }
      }
    });
  }, []);


  return (
    <div className="!z-5 view-content ">
      <TFNAnalyticsComponent
        item={item}
        className="h-max min-h-screen"
        gridName={gridCaption}
        endpoint={resolvedEndPoint}
      />
    </div>

  );
};

export default GeneralDataTablePageView;
