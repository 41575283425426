import React, { createRef, useCallback, useRef, useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import api from "api";
import * as utils from '../../utils/utils.js'
import { useNavigate, useParams } from 'react-router-dom';

import {
    CompareRule,
    Form,
    Label,
    RequiredRule,
    SimpleItem,
} from 'devextreme-react/form';

// MyPopupAccountComponent this is to change current user's passowrd
export default function MyPopupAccountComponent(props) {

    var container = props.container
    const [consentTitle, setConsentTitle] = useState("My Account");
    const { ...urlParams } = useParams();

    // this is the item = my-account
    const item =
    {
        "password": "",
        "new_password": "",
        "confirm_new_password": "",
    }

    // // this will submit an API request for the consent and then close the window
    const acceptAndCloseFn = useCallback((e) => {
        if (item.new_password != item.confirm_new_password) return;
        const data = item;
        const endpoint = '/me/password';
        const resolvedEndPoint = utils.resolveEndPointStr(endpoint, data, urlParams);
        api.method("PUT", resolvedEndPoint, data, (response) => {
            console.log("executeCommand1", response);
            if (response.meta.status == 200) {
                const msg = "Password changed succesfully, please re-login!";
                api.showMessage(msg, 'success', 'top center');
                api.logout(api.endpoint_admin_signout);
            }
        });
    }, []);

    // // if they clsoe it, then we dont save, and it will popup again
    // const hideInfo = useCallback(() => {
    //     console.log("props.popupVisible hideinfox",props,props.popupVisible);
    //     // props.visible = false;
    //     setprops.popupVisible(false);
    //     console.log("props.popupVisible hideinfoy",props,props.popupVisible);
    // }, []);

    const getSaveButtonOptions = useCallback(
        () => ({
            // icon: 'check',
            text: 'Save',
            value: true,
            onClick: acceptAndCloseFn,
        }),
        [acceptAndCloseFn],
    );

    const getCancelButtonOptions = useCallback(
        () => ({
            // icon: 'check',
            text: 'Cancel',
            // value: false,
            onClick: props.onHide,
        }),
        // [onOpenPopup],
    );

    const formRef = useRef(null);

    const passwordComparison = () => item.new_password;
    // const changePasswordMode = useCallback((name) => {
    //     const editor = formRef.current.instance().getEditor(name);
    //     editor.option('mode', editor.option('mode') === 'text' ? 'password' : 'text');
    // }, []);

    const getPasswordOptions = useCallback(
        () => ({
            mode: 'password',
            // valueChangeEvent: 'keyup',
            // onValueChanged: () => {
            //     // console.log("formRef", formRef.current);
            //     // const editor = formRef.current.instance().getEditor('ConfirmPassword');
            //     // if (editor.option('value')) {
            //     //     const instance = Validator.getInstance(editor.element());
            //     //     instance.validate();
            //     // }
            // },
            // buttons: [
            //     {
            //         name: 'password',
            //         location: 'after',
            //         options: {
            //             stylingMode: 'text',
            //             icon: 'fa fa-eye',
            //             onClick: () => changePasswordMode('NewPassword'),
            //         },
            //     },
            // ],
        }),
        [],
    );
    const getConfirmOptions = useCallback(
        () => ({
            mode: 'password',
            valueChangeEvent: 'keyup',
            // buttons: [
            //     {
            //         name: 'password',
            //         location: 'after',
            //         options: {
            //             stylingMode: 'text',
            //             icon: 'fa fa-eye',
            //             onClick: () => changePasswordMode('ConfirmPassword'),
            //         },
            //     },
            // ],
        }),
        [],
    );
    const handleSubmit = useCallback((e) => {
        console.log("handlesumbit", e);
        e.preventDefault();
    }, []);
    const checkPassConfim = () => {
        return (item.new_password != item.confirm_new_password);
    }
    return (
        <form
            action="your-action"
            onSubmit={handleSubmit}
        >
            <Popup
                container={container || '.app_main'}
                visible={props.popupVisible}
                onHiding={props.onHide}
                hideOnOutsideClick={false}
                showCloseButton={false}
                hideOnParentScroll={false}
                dragEnabled={false}
                showTitle={true}
                title={consentTitle}>

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={checkPassConfim()}
                    options={getSaveButtonOptions()}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={getCancelButtonOptions()}
                />
                <Form formData={item}
                    ref={formRef}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                >

                    <SimpleItem
                        name="CurrentPassword"
                        dataField="password"
                        editorType="dxTextBox"
                        editorOptions={getPasswordOptions()}
                    >
                        <Label text="Current Password" />
                        <RequiredRule message="Password is required" />
                    </SimpleItem>

                    <SimpleItem
                        name="NewPassword"
                        dataField="new_password"
                        editorType="dxTextBox"
                        editorOptions={getPasswordOptions()}
                    >
                        <Label text="New Password" />
                        <RequiredRule message="New Password is required" />
                    </SimpleItem>

                    <SimpleItem
                        name="ConfirmPassword"
                        dataField="confirm_new_password"
                        editorType="dxTextBox"
                        editorOptions={getConfirmOptions()}
                    >
                        <Label text="Confirm Password" />
                        <RequiredRule message="Confirm Password is required" />
                        <CompareRule
                            message="New Password and Confirm Password do not match"
                            comparisonTarget={passwordComparison}
                        />
                    </SimpleItem>
                </Form>
            </Popup>
        </form>
    );
}
