// this file contains the registry of all components that will be used to map the views.
// the input is the component name, and the output is the 'TagName' with the 'item' data to be passed to the actual component.
// this use of 'key = date' to force reload in case of using the same component for multuple views.

// this is the list of all built components
import React, { Component } from 'react';
import TFNAnalyticsComponent from 'views/admin/analytics/index.jsx';
import GeneralDataTablePageView from 'views/admin/tables/index.jsx';
import ErrorComponent from './error/index.jsx';

class ComponentRegistry extends Component {
    constructor(props) {
        super(props);

        // always use lowercase for the key value
        // i.e. "home": Home, and not "Home": Home
        this.components = {
            "ErrorComponent": ErrorComponent,
            "TFNAnalyticsComponent":TFNAnalyticsComponent,
            "GeneralDataTablePageView":GeneralDataTablePageView,
        };
    };

    findVal(obj, prop) {
        prop = (prop + "").toLowerCase();
        for (var p in obj) {
            if (obj.hasOwnProperty(p) && prop == 
                (p + "").toLowerCase()) {
                return obj[p];
            }
        }
        return ErrorComponent;
    }

    render() {

        console.log("ComponentRegistry",this.props);
        const tag = (this.props.tag || 'ErrorComponent').toLowerCase();        
        const TagName = this.findVal(this.components,tag);

        // we're making the component key as item ID, menu id and endpoint (should be unique per view/page).
        // then in case there is an error or a null, we just set the key to timestamp, forcing regeneation of component
        const x = this.props.item;
        const key = ((x.id+"-"+x.menu_id+"-"+x.endpoint) || "tag"+Date.now()); 
        
        return <TagName item={this.props.item} tag={tag} {...this.props}  key={key}/>;
    }
}
export default ComponentRegistry;