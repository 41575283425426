import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
// import "./gsma.css";
import "./gsma-gcc.css";
import "./gsma-irsf-custom.css";
// import "./env-config";

import "./gsma-irsf-custom.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
