
import moment from 'moment';
import { countries } from './consts';
import { useEffect, useRef } from 'react';


export const useOnUnmount = (fn) => {
    const fnRef = useRef(fn);
    fnRef.current = fn;
    useEffect(
        () => () => {
            fnRef.current();
        }, []
    );
}


export function getCountry(iso) {

    const notFound = {
        "alpha2": ((!iso || iso=="ZZ") ?"UN": iso),
        "name": "Unknown Country " + iso + ""
    };

    var chek = countries.find(c => c.alpha2 === iso);

    var country = chek ? chek : notFound
    if (country.alpha2 == "ZZ") {country.alpha2="UN"}
    // console.log("getCountry", country)

    return country;
}

export function ProcessPath(path) {
    var domain = "http://example.com";
    if (!isEmpty(path) && path.substring(0, 1) !== '/') {
        domain = domain + '/';
    }

    var fullpath = domain + path;
    const url = new URL(fullpath);
    return url;
}


export function isFieldFiltered(field) {
    const params = new URLSearchParams(window.location.search);
    for (let item of params) {
        console.log('isFieldFiltered', field, item[1].substring(0, field.length));
        if (item[0].substring(0, field.length) == field) {
            return true;
        }
    }
    return false;
}// true|false


// do the actual navigation
export function getNavOps(path, qs, hsh) {
    if (path == undefined) {
        console.log("path", path);
        return;
    }

    if (path !== '#') {
        let navops = {};
        navops.pathname = path;
        navops.search = "";
        navops.hash = "";

        if (qs !== undefined) {
            navops.search = qs;
        }

        if (hsh !== undefined) {
            navops.hash = hsh
        }

        //   console.log("nagivateToPath", navops);
        return navops;

        //   navigate(navops, {
        //     replace: true,
        //   });

    } else {
        console.log('no path', path);
        return "";
    }
};

export function reverseString(str) {
    return str.split("").reverse().join("");
}


export function isFieldSorted(field) {
    // console.log('isFieldSorted', field);

    // var sort = null;

    const params = new URLSearchParams(window.location.search);
    var orderBy = params.get("_orderby");

    // if orderby is set, let's check which fields are already set.
    if (!isEmpty(orderBy)) {
        var setFields = orderBy.split(",");
        
        // let's loop to see if the field we have is already set
        // var blFound = false;

        // if set, it could be as <field> | <field_asc> | <field_desc>
        for (var i = 0; i < setFields.length; i++) {
            var setField = setFields[i];
            const parts = setField.split("_");
            console.log('isFieldSorted2 ', field, setFields[i],setField.substring(0, field.length+1),parts,parts[parts.length-1]);
            
            // if setField is the field we have but with order-operator 
            if (setField.substring(0, field.length) == field) {
                // let's split by _ and get the last part.
                // then check if it's asc or desc
                if (parts[parts.length-1] == "asc") return parts[parts.length-1];
                if (parts[parts.length-1] == "desc") return parts[parts.length-1];
                // if it's neighter, then it's asc, return desc
                return "desc";


                // if (setField.substring(setField.length - 4, setField.length) == "_asc") {
                //     //we switch the order to_desc
                //     return "asc";
                // } else if ((setField.substring(setField.length - 5, setField.length) == "_desc") || setField.length == field.length) {
                //     //we switch the order to_asc
                //     return "desc";
                // }
            }

        }
    }
    return null;
}//null|asc|desc

// this function will check if we have _orderby set.
// if so, then we check if the field is already in the list.
// if not, then we set it as 'field'_asc
// if so, then we switch the order (e.g. if id|id_asc -> id_desc)
// if not, then we add it
export function urlOrderBy(field, blreload) {

    const params = new URLSearchParams(window.location.search);
    var orderBy = params.get("_orderby");

    // no field is set yet.
    if (!orderBy) {
        params.set("_orderby", field + "_asc");
        const out = window.location.pathname + "?" + decodeURIComponent(params) + window.location.hash;
        window.history.pushState("", "", out);
        const x = blreload ? window.location.reload() : null;
        return;
    }

    // if orderby is set, let's check which fields are already set.
    // if (orderBy) {
    var setFields = orderBy.split(",");
    // let's loop to see if the field we have is already set
    var blFound = false;
    // if set, it could be as <field> | <field_asc> | <field_desc>
    for (var i = 0; i < setFields.length; i++) {
        var setField = setFields[i];
        // if setField is the field we have but with order-operator 
        console.log('urlOrderBy', setField.substring(0, field.length), field);
        if (setField.substring(0, field.length) == field) {
            if (setField.substring(setField.length - 4, setField.length) == "_asc") {
                //we switch the order to_desc
                setFields[i] = field + "_desc";
                // params.set("_orderby", );
            } else if ((setField.substring(setField.length - 5, setField.length) == "_desc") || setField.length == field.length) {
                //we switch the order to_asc
                setFields[i] = field + "_asc";
                // params.set("_orderby", field + "_asc");
            }
            blFound = true;
            break;
        }
    }
    if (!blFound) {
        setFields.push(field + "_asc");
        // params.set("_orderby", field + "_asc");
    }

    params.set("_orderby", setFields.join(","));
    console.log("urlOrderBy", params.get("_orderby"));

    const out = window.location.pathname + "?" + decodeURIComponent(params) + window.location.hash;
    window.history.pushState("", "", (out));
    const x = blreload ? window.location.reload() : null;
    // }

}
export function updateUrlParameter(endpoint, param, value) {
    const newUrl = window.location.href;
    const out = ReplaceUrlParam(newUrl, param, value);
    console.log("updateUrlParameter",param,value,newUrl,out);
    window.history.pushState("", "", out);
    // return out;

    return updateQueryStringParameter(endpoint,param,value);
}

export function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

// // ReplacePathParam this function would resolve :id -> 1
// export function ReplacePathParam(url, paramName, paramValue) {
//     if (paramValue == null) {
//         paramValue = '';
//     }

//     var out = url; //default not resolved 
//     const regex = new RegExp('(:' + paramName + ').*?(\\/|#|$)', 'gmi');

//     // var pattern = new RegExp(regex);
//     var m = regex.exec(url);
//     // The result can be accessed through the `m`-variable.
//     m.forEach((match) => {
//         out = url.replace(regex, paramValue, match);
//         return out;
//     });

//     return out;
// }

// add params to querystirng url
export function ReplaceUrlParam(url, paramName, paramValue) {
    if (paramValue == null) {
        paramValue = '';
    }

    // var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    // if (url.search(pattern) >= 0) {
    //     return url.replace(pattern, '$1' + paramValue + '$2');
    // }

    const tmpURL = ProcessPath(url);
    tmpURL.searchParams.set(paramName, paramValue);
    return  decodeURIComponent(tmpURL.search) + tmpURL.hash;

}


export function isEmpty(value) {
    return !(value && value.length > 0 && value != "");
}


export function setPageTitleElement(str,newContent) {
    
    var aTags = document.getElementsByTagName("div");
    var searchText = str;
    // var found;

    for (var i = 0; i < aTags.length; i++) {
        console.log(searchText,aTags[i].textContent);
        if (aTags[i].textContent == searchText) {
            aTags[i].innerHTML = newContent;
            break;
        }
    }

}

export function capitalize(str) {
        return str
        .replace(/_/g, ' ')
            .split(' ')
            .map(function(word) {
                return word[0].toUpperCase() + word.substr(1);
            })
            .join(' ');         
}

export function getStartDate(blReturnDate) {
    const Field = "_start";
    const DATE_RANGE = window._env_.REACT_APP_INIT_DATE_RANGE || process.env.REACT_APP_INIT_DATE_RANGE || 7;
    const val = moment().subtract(DATE_RANGE, 'days').format("YYYYMMDD");
    // let's check if _start is set.
    // if not, then we set it based on REACT_APP_DEFAULT_DATERANGE
    const params = new URLSearchParams(window.location.search);
    const x = params.get(Field) ? params.get(Field) : val;
    if (blReturnDate) {
        return moment(x, "YYYYMMDD").toDate()
    }
    return x;
}

export function getEndDate(blReturnDate) {
    const Field = "_end";
    const val = moment().format("YYYYMMDD");
    const params = new URLSearchParams(window.location.search);

    const x = params.get(Field) ? params.get(Field) : val;
    if (blReturnDate) {
        return moment(x, "YYYYMMDD").toDate()
    }
    return x;
}


export function filterByField(field, operator, data) {
    console.log("filterByField", field, data);
    const filter = operator && operator != "" && operator != "=" ? field + "_" + operator : field;
    return updateUrlParameter(filter, data);

}

export function filterIsSet() {
    let params = new URLSearchParams(window.location.search);
    for (let item of params) {
        if (item[0].substr(0, 1) != "_") {
            return true;
        }
    }
    return false;
}
 
// this function will read all URL params, and remove them except if they start with _ (e.g. _limit, _page, _start & _end ...etc)
export function resetURLParams(blreload) {

    let params = new URLSearchParams(window.location.search);
    let new_params = new URLSearchParams(window.location.search);
    
    for (let item of params) {
        if (item[0].substr(0, 1) != "_") {
            console.log("resetURLParams", item);
            new_params.delete(item[0]);
        }
    }

    const out = window.location.pathname + "?" + new_params + window.location.hash;
    console.log("resetURLParams out", out);
    window.history.pushState("", "", out);
    const x = blreload ? window.location.reload() : null;
}


export function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].key === obj.key) {
            return true;
        }
    }
    return false;
}

// this function takes path (e.g. /scenarios/{id}/records) and data (e.g. {id:1,name:'x',...}) and resolve the path with the matched key
function createStringFromTemplate(template, variables) {
    return template.replace(new RegExp("\{([^\{]+)\}", "g"), function (_unused, varName) {
        return variables[varName];
    });
}

export function resolvePathVars(path, data) {
    console.log("resolvePathVars", path, data);
    return createStringFromTemplate(path, data)
}


export function stringFromTemplate(template, variables) {
    return template.replace(new RegExp("\{([^\{]+)\}", "g"), function (_unused, varName) {
        return variables[varName];
    });
}

String.prototype.left = function (n) {
    return this.substring(0, n);
}

export function merge_options(obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) {
        // console.log("merge_options x1",attrname,obj1[attrname],obj2[attrname],obj3[attrname]);
        obj3[attrname] = obj1[attrname];
    }
    for (var attrname in obj2) {
        // console.log("merge_options x2",attrname,obj1[attrname],obj2[attrname],obj3[attrname]);
        // add it only if the key is not in already, or the value is empty
        if (obj3[attrname] == undefined) {
            obj3[attrname] = obj2[attrname];
        // } else if (obj3[attrname] == "") {
        //     obj3[attrname] = obj2[attrname];
        }
        // console.log("merge_options x3",attrname,obj1[attrname],obj2[attrname],obj3[attrname]);
    }

    return obj3;
}


export function resolveActionsEndPoint(allowedActions, actionName, data,urlParams) {
    for (var i = 0; i < allowedActions.length; i++) {
      if (allowedActions[i].name == actionName) {
        const action = allowedActions[i];
        resolveEndPointStr(action.endpoint,data);
        let resolvedEndPoint = resolveEndPointStr(action.endpoint,data,urlParams); //action.endpoint;
        // // first resolve by URL params

        // let resolvVars = utils.merge_options(urlParams, data);
        // resolvedEndPoint = utils.stringFromTemplate(resolvedEndPoint, resolvVars);

        // console.log("executeCommand resolvedEndPoint", action.endpoint, resolvedEndPoint, urlParams, data, resolvVars);

        // here we need to construct the reques
        return resolvedEndPoint;
      }
    }
  }

  export function resolveEndPointStr(resolvedEndPoint, data,urlParams) {
        let resolvVars = merge_options(urlParams, data);
        resolvedEndPoint = stringFromTemplate(resolvedEndPoint, resolvVars);
        // here we need to construct the reques
        return resolvedEndPoint;
  }

  export function sort_by(field, reverse, primer) {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }